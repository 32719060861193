.position-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
}

.injury-time-container {
    position: absolute;
    display: flex;
    justify-content: center;
    place-items: center;
    top: 30px;
    z-index: 2;
    width: 100%;
    height: auto;
    animation: cssAnimation 0s ease-in 5s forwards, 1s fadeIn;
    animation-fill-mode: forwards;
    visibility: hidden;
}

.injury-time {
    display: flex;
    place-items: center;
    padding: 0 0.5em;
    justify-content: space-around;
    background-color: white;
    border-radius: 1px;
    font-size: 14px;
    min-width: 15%;
    margin-top: 1rem;
    margin-bottom: 10px;
}

.injury-time-without-margin {
    display: flex;
    place-items: center;
    padding: 0 0.5em;
    justify-content: space-around;
    background-color: white;
    color: red;
    border-radius: 1px;
    font-size: 14px;
    min-width: 15%;
}

@keyframes cssAnimation {
    to {
        width:0;
        height:0;
        top: 0;
        overflow:hidden;
        padding: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        visibility: visible;
        opacity: 1;
    }
}
