html {
    scroll-behavior: smooth !important;
}

button {
    border: 0 solid #e5e7eb;
    box-sizing: border-box;
}

a {
    color: inherit;
    text-decoration: inherit;
}

.general-digital-section blockquote,
.general-digital-section dd,
.general-digital-section dl,
.general-digital-section figure,
.general-digital-section h1,
.general-digital-section h2,
.general-digital-section h3,
.general-digital-section h4,
.general-digital-section h5,
.general-digital-section h6,
.general-digital-section hr,
.general-digital-section p,
.general-digital-section pre {
    margin: 0;
}

menu, ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

button, input:where([type=button]), input:where([type=reset]), input:where([type=submit]) {
    -webkit-appearance: button;
    background-color: initial;
    background-image: none;
}

button, select {
    text-transform: none;
}
button, input, optgroup, select, textarea {
    font-feature-settings: inherit;
    color: inherit;
    font-family: inherit;
    font-size: 100%;
    font-variation-settings: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
}

.general-digital-section {
    background: #fff;
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.justify-between {
    justify-content: space-between;
}

img, video {
    height: auto;
    max-width: 100%;
}

.items-center {
    align-items: center;
}

.h-\[90px\] {
    height: 90px;
}

.flex {
    display: flex;
}

.w-72 {
    width: 18rem;
}

.box-shadow {
    box-shadow: 0 4px 27px 0 #00000026;
}

.pl-8 {
    padding-left: 2rem;
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.rounded-3xl {
    border-radius: 1.5rem;
}

.cursor-pointer {
    cursor: pointer;
}

.h-\[50px\] {
    height: 50px;
}

.z-50 {
    z-index: 50;
}

.max-w-\[100px\] {
    max-width: 100px;
}

.h-8 {
    height: 2rem;
}

.duration-500 {
    transition-duration: .5s;
}

.transition {
    transition-duration: .15s;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}
.top-4 {
    top: 1rem;
}
.right-6 {
    right: 1.5rem;
}
.absolute {
    position: absolute;
}
audio, canvas, embed, iframe, img, object, svg, video {
    display: block;
    vertical-align: middle;
}

.px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
}

.items-center {
    align-items: center;
}

.w-full {
    width: 100%;
}

.h-13 {
    height: 3.25rem;
}

.gap-8 {
    gap: 2rem;
}

.relative {
    position: relative;
}

.opacity-80 {
    opacity: .8;
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-\[\#FD9790\] {
    --tw-bg-opacity: 1;
    background-color: rgb(253 151 144 / var(--tw-bg-opacity));
}

.border-\[\#FD9790\] {
    --tw-border-opacity: 1;
    border-color: rgb(253 151 144 / var(--tw-border-opacity));
}
.border {
    border: 1px solid;
}
.rounded-lg {
    border-radius: .5rem;
}

.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-base {
    font-size: .9rem;
    line-height: 1.5rem;
}
.px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.text-\[\#FD9790\] {
    --tw-text-opacity: 1;
    color: rgb(253 151 144 / var(--tw-text-opacity));
}

.-translate-y-1\/2 {
    --tw-translate-y: -50%;
}

.transform {
}

.top-1\/2 {
    top: 50%;
}
.right-0\.5 {
    right: .125rem;
}

audio, canvas, embed, iframe, img, object, svg, video {
    display: block;
    vertical-align: middle;
}

.right-0\.5 {
    right: .125rem;
}

.w-auto {
    width: auto;
}

.my-auto {
    margin-bottom: auto;
    margin-top: auto;
}
.z-10 {
    z-index: 10;
}

.font-light {
    font-weight: 300;
}

.text-center {
    text-align: center;
}

.duration-300 {
    transition-duration: .3s;
}

.transition-all {
    transition-duration: .15s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}
.shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
}

.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.h-11 {
    height: 2.75rem;
}

.w-60 {
    width: 15rem;
}

.rounded-full {
    border-radius: 9999px;
}
.overflow-hidden {
    overflow: hidden;
}

.z-0 {
    z-index: 0;
}

.top-0 {
    top: 0;
}
.bottom-0 {
    bottom: 0;
}

.gap-3 {
    gap: .75rem;
}

.pr-5 {
    padding-right: 1.25rem;
}

.px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
}

.right-0\.5 {
    right: .125rem;
}

.submenu-header {
    justify-content: flex-end !important;
}
.header-visible {
    top: 0;
    transition: top .4s ease-out;
    z-index: 40;
}
.shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
}
.h-14, .h-16 {
    height: 4rem;
}

.overflow-x-auto {
    overflow-x: auto;
}

.text-\[\#81838C\] {
    --tw-text-opacity: 1;
    color: rgb(129 131 140 / var(--tw-text-opacity));
}

.pb-1 {
    padding-bottom: .25rem;
}

.flex-shrink-0 {
    flex-shrink: 0;
}


.max-w-\[1440px\] {
    max-width: 1440px;
}

.w-full {
    width: 100%;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.py-6 {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
}

.px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
}

.gap-5 {
    gap: 1.25rem;
}

.flex-col {
    flex-direction: column;
}

.max-w-\[1440px\] {
    max-width: 1440px;
}

.mt-5 {
    margin-top: 1.25rem;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}

.font-semibold {
    font-weight: 500;
}
.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}

.h-auto {
    height: auto;
}

.nav-v-pill-wrapper .active {
    box-shadow: none;
}

.nav-v-pill-wrapper li {
    border: 0;
    position: relative;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.text-md {
    font-size: .875rem;
}

.h-10 {
    height: 2.5rem;
}

.bg-\[\#FBFBFB\] {
    --tw-bg-opacity: 1;
    background-color: rgb(251 251 251 / var(--tw-bg-opacity));
}

.pb-24 {
    padding-bottom: 6rem;
}

.gap-12 {
    gap: 3rem;
}

.my-8 {
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.bg-\[\#FFE3E5\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 227 229 / var(--tw-bg-opacity));
}

.w-20 {
    width: 5rem;
}
.h-20 {
    height: 4rem;
}
.right-5 {
    right: 1.25rem;
}
.-top-16 {
    top: -4rem;
}

.text-\[42px\] {
    font-size: 42px;
}

.xl\:w-\[950px\] {
    width: 950px;
}

.text-\[\#697883\] {
    --tw-text-opacity: 1;
    color: rgb(105 120 131 / var(--tw-text-opacity));
}

.gap-1 {
    gap: .25rem;
}
.justify-start {
    justify-content: flex-start;
}

.items-start {
    align-items: flex-start;
}

.left-0 {
    left: 0;
}

.pr-2 {
    padding-right: .5rem;
}
.pl-3 {
    padding-left: .75rem;
}

.box-shadow {
    box-shadow: 0 4px 27px 0 #00000026;
}
.duration-500 {
    transition-duration: .5s;
}

.border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.rounded-xl {
    border-radius: .75rem;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
}

.ml-5 {
    margin-left: 1.25rem;
}

.w-13 {
    width: 3.25rem;
}

.mt-24 {
    margin-top: 6rem;
}

.swiper-container {
    list-style: none;
    margin: 0 auto;
    overflow: hidden;
    padding: 0;
    position: relative;
    z-index: 1;
}

.swiper-container {
    overflow: visible !important;
    overflow: initial !important;
    overflow-x: clip !important;
}

.swiper-container .swiper-button-prev {
    transform: rotate(180deg);
}

.swiper-container .swiper-button-next, .swiper-container .swiper-button-prev {
    background: url(https://srv-cdn.onedio.com/store/c012124….png) no-repeat 50% #222;
    background-size: 40%;
    border-radius: 50%;
    height: 40px;
    width: 40px;
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    left: auto;
    right: 10px;
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    left: 10px;
    right: auto;
}
.swiper-button-next, .swiper-button-prev {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 27px 44px;
    cursor: pointer;
    height: 44px;
    margin-top: -22px;
    position: absolute;
    top: 50%;
    width: 27px;
    z-index: 10;
}

.swiper-container .swiper-button-next, .swiper-container .swiper-button-prev {
    background: url(https://srv-cdn.onedio.com/store/c012124….png) no-repeat 50% #222;
    background-size: 40%;
    border-radius: 50%;
    height: 40px;
    width: 40px;
}

.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 10px;
    left: 0;
    width: 100%;
}

.swiper-container .swiper-pagination {
    bottom: -50px !important;
}
.swiper-pagination {
    position: absolute;
    text-align: center;
    transform: translateZ(0);
    transition: opacity .3s;
    z-index: 10;
}

.justify-center {
    justify-content: center;
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 4px;
}

.swiper-container .swiper-pagination .swiper-pagination-bullet-active {
    background: #000 !important;
}
.swiper-container .swiper-pagination .swiper-pagination-bullet {
    background: #c4c4c4;
    height: 14px;
    opacity: 1;
    width: 14px;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
}
.swiper-pagination-bullet-active {
    background: #007aff;
    opacity: 1;
}
.swiper-pagination-bullet {
    background: #000;
    border-radius: 100%;
    display: inline-block;
    height: 8px;
    opacity: .2;
    width: 8px;
}

.swiper-container-android .swiper-slide, .swiper-wrapper {
    transform: translateZ(0);
}

.swiper-wrapper {
    box-sizing: initial;
    display: flex;
    height: 100%;
    position: relative;
    transition-property: transform;
    width: 100%;
    z-index: 1;
}

.swiper-container .swiper-slide {
    opacity: .5;
    pointer-events: none;
    transform: scale(.8);
}

.swiper-slide {
    flex-shrink: 0;
    height: 100%;
    position: relative;
    transition-property: transform;
    width: 100%;
}

.gap-4 {
    gap: 1rem;
}

.w-\[90\%\] {
    width: 90%;
}

.bg-\[\#fb968f\] {
    --tw-bg-opacity: 1;
    background-color: rgb(251 150 143 / var(--tw-bg-opacity));
}

.w-4 {
    width: 1rem;
}

.h-4 {
    height: 1rem;
}

.swiper-container .swiper-slide-active {
    cursor: pointer;
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
}

.pr-16 {
    padding-right: 4rem;
}
.pl-4 {
    padding-left: 1rem;
}

.min-w-44 {
    min-width: 5rem;
}

.-right-3 {
    right: -.75rem;
}

.h-12 {
    height: 3rem;
}

.w-16 {
    width: 4rem;
}

.-right-4 {
    right: -1rem;
}

.bg-\[\#FEF9F3\] {
    --tw-bg-opacity: 1;
    background-color: rgb(254 249 243 / var(--tw-bg-opacity));
}

.bg-\[\#F3FCF6\] {
    --tw-bg-opacity: 1;
    background-color: rgb(243 252 246 / var(--tw-bg-opacity));
}

.bg-\[\#fdf2f0\] {
    --tw-bg-opacity: 1;
    background-color: rgb(253 242 240 / var(--tw-bg-opacity));
}

.bg-\[\#ffefdd\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 239 221 / var(--tw-bg-opacity));
}

.bg-\[\#f7f7f7\] {
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
}

.bg-\[\#F0F2EE\] {
    --tw-bg-opacity: 1;
    background-color: rgb(240 242 238 / var(--tw-bg-opacity));
}

.rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
}

.gap-6 {
    gap: 1.5rem;
}

.p-12 {
    padding: 3rem;
}

.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
}

.text-blue-300 {
    --tw-text-opacity: 1;
    color: rgb(147 197 253 / var(--tw-text-opacity));
}

.outline-none {
    outline: 2px solid #0000;
    outline-offset: 2px;
    border: none;
}

.pl-5 {
    padding-left: 1.25rem;
}

.p-2 {
    padding: .5rem;
}

.mt-20 {
    margin-top: 5rem;
}

@media (min-width: 1024px) {
    .lg\:hidden {
        display: none;
    }

    .lg\:flex {
        display: flex;
    }

    .lg\:px-10 {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

    .lg\:px-20 {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .lg\:justify-center {
        justify-content: center;
    }

    .lg\:py-16 {
        padding-bottom: 4rem;
        padding-top: 4rem;
    }

    .lg\:text-5xl {
        font-size: 3rem;
        line-height: 1;
    }

    .lg\:flex-row {
        flex-direction: row;
    }

    .lg\:flex-col {
        flex-direction: column;
    }

    .lg\:w-auto {
        width: auto;
    }

    .lg\:relative {
        position: relative;
    }

    .lg\:pl-4 {
        padding-left: 1rem;
    }

    .lg\:w-\[460px\] {
        width: 460px;
    }

    .lg\:h-16 {
        height: 4rem;
    }

    .lg\:w-\[250px\] {
        width: 200px;
    }
}

@media (min-width: 769px) {
    .nav-v-pill-wrapper .active {
        border: 1px solid #f6d3d3;
    }

    .nav-v-pill-wrapper li {
        border: 1px solid #00000008;
        cursor: pointer;
        position: relative;
    }
}

@media (min-width: 640px) {
    .sm\:w-\[480px\] {
        width: 480px;
    }

    .sm\:h-full {
        height: 100%;
    }

    .sm\:h-\[290px\] {
        height: 290px;
    }

    .sm\:h-\[90px\] {
        height: 90px;
    }

    .lg\:w-full {
        width: 100%;
    }

    .sm\:w-36 {
        width: 9rem;
    }

    .sm\:h-36 {
        height: 9rem;
    }

    .sm\:-top-24 {
        top: -6rem;
    }

    .sm\:scale-100 {
        --tw-scale-x: 1;
        --tw-scale-y: 1;
    }

    .sm\:-right-12 {
        right: -3rem;
    }

    .sm\:max-w-\[1440px\] {
        max-width: 1440px;
    }

    .sm\:overflow-visible {
        overflow: visible;
    }

    .sm\:gap-6 {
        gap: 1.5rem;
    }

    .sm\:justify-center {
        justify-content: center;
    }

    .sm\:max-w-\[1440px\] {
        max-width: 1440px;
    }

    .sm\:h-32 {
        height: 8rem;
    }

    .sm\:relative {
        position: relative;
    }

    .sm\:mt-0 {
        margin-top: 0;
    }

    .sm\:-right-6 {
        right: -1.5rem;
    }

    .sm\:-bottom-16 {
        bottom: -4rem;
    }

    .sm\:w-12 {
        width: 3rem;
    }

    .sm\:h-12 {
        height: 3rem;
    }

    .sm\:text-4xl {
        font-size: 2.25rem;
        line-height: 2.5rem;
    }

    .sm\:text-xl {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }

    .sm\:w-80 {
        width: 20rem;
    }

}


