#goal-block-container {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    background: url(https://secure.static.visualisation.performgroup.com/csb/default/1/images/soccer/goal.jpg);
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 100%;
}

.general-content-container {
    display: flex;
    justify-content: center;
    place-items: center;
    width: 100%;
    height: 100%;
}

.content-section {
    display: flex;
    flex-direction: column;
    width: 185px;
}

.goal-text {
    display: flex;
    justify-content: center;
    place-items: center;
    font-size: 15px;
    font-weight: bolder;
    color: white;
    padding: 8px;
    background-color: #1d1d1d;
}

.result {
    display: flex;
    justify-content: center;
    place-items: center;
    color: white;
    font-size: 13px;
    font-weight: bold;
    padding: 8px;
    background-color: #424242;
    animation: .8s fadeIn;
    animation-fill-mode: forwards;
    visibility: hidden;
}

.team-name-container {
    position: relative;
    top: 0;
    display: flex;
    justify-content: center;
    place-items: center;
    padding: 8px;
    background-color: #000000;
    opacity: 0.6;
    z-index: 0;
}

.team-name {
    color: white;
    font-size: 14px;
    font-weight: 300;
}

.blink {
    animation: reveal .5s forwards;
}

@keyframes reveal {
    from {
        clip-path: inset(0 100% 0 0);
    }
    to {
        clip-path: inset(0 0 0 0);
    }
}

@keyframes fadeIn {
    99% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
}
