.blink {
    animation: reveal .5s forwards;
}

@keyframes reveal {
    from {
        clip-path: inset(100% 100% 0 0);
    }
    to {
        clip-path: inset(0 0 0 0);
    }
}

.substitution-container {
    position: absolute;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 2px;
}

.player-out-section {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.arrow-container {
    display: flex;
    flex-direction: column;
    place-items: center;
}

.substitution-player-name {
    color: #00a7ff;
    font-size: 12px;
    font-weight: 500;
}

.substitution-number {
    font-weight: 300;
}

.substitution-text-section {
    display: flex;
    justify-content: center;
    width: 100%;
}

.player-in-section {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.player-out-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    place-items: center;
    padding: 0 10px;
    border-radius: 0 0 10px 0;
    width: 220px;
    height: 40px;
    background-color: white;
}

.substitution-player-name-and-number {
    display: flex;
    flex-direction: column;
}

.substitution-container-text {
    display: flex;
    place-items: center;
    justify-content: center;
    width: 40%;
    height: 50px;
    font-size: 15px;
    font-weight: 300;
    color: #909090;
    background-color: white;
}

.player-in-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    place-items: center;
    padding: 0 10px;
    border-radius: 0 0 0 10px;
    width: 220px;
    height: 40px;
    background-color: white;
}

.arrow-below-text {
    color: #afafaf;
    font-weight: 500;
    font-size: 11px;
}

.position-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
}
