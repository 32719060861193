.carousel {
    width: 70%;
    height: 70%;
    overflow: hidden;
    margin: 0 auto;
}

.carousel-items-container {
    position: relative;
    height: 80%;
}

.carousel-item {
    width: 100%;
    height: 100%;
    position: absolute;
    transition: margin .3s;
}


.item-1 {
    background: white;
}

.item-2 {
    margin-left: 100%;
    background: white;
}

.item-3 {
    margin-left: 200%;
    background: white;
}

.item-4 {
    margin-left: 300%;
    background: white;
}

.carousel-buttons-container {
    padding: 1rem;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.carousel-button {
    margin: 0 3px;
    border: 1px solid #555;
    background: #555;
    width: 25px;
    height: 5px;
}

@media only screen and (max-width: 960px) {
    .carousel-items-container {
        height: 85%;
    }
}

@media only screen and (max-width: 540px) {
    .carousel-items-container {
        height: 85%;
    }
}

@media only screen and (max-width: 420px) {
    .carousel-items-container {
        height: 80%;
    }
}
