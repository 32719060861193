.general-section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}

.match-result-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    place-items: center;
}

.match-ended {
    font-size: 10px;
}

.font-size {
    font-size: 10px;
}

.font-size2 {
    font-size: 9px;
}

.referee {
    font-size: 9px;
}

.referee-text {
    font-size: 8px;
}

.manager-and-team-section {
    display: flex;
    justify-content: space-between;
    padding: 0 1.5rem;
    place-items: center;
    width: 100%;
}

.hr {
    height: 1px;
    border-width: 0;
    background-color: #c9c9c9;
}

.match-result-container {
    display: flex;
    justify-content: center;
    place-items: center;
    width: 100px;
}

.match-result {
    display: flex;
    justify-content: center;
    place-items: center;
    background-color: red;
    width: 1.2rem;
    height: 1.2rem;
    font-size: 10px;
    color: white;
    border-radius: 2px;
    font-weight: bold;
}

.icon {
    width: 0.6rem;
    height: 0.6rem;
}

.referee-container {
    display: grid;
    place-items: center;
}

.futbol-verileri-container {
    display: grid;
    justify-content: center;
    place-items: center;
}

.fv-img {
    width: 60%;
    height: 75%;
}

@media only screen and (max-width: 1264px) {

    .match-ended {
        font-size: 15px;
    }

    .font-size {
        font-size: 15px;
    }

    .font-size2 {
        font-size: 14px;
    }

    .match-result {
        font-size: 16px;
    }
}

@media only screen and (max-width: 960px) {
    .match-ended {
        font-size: 12px;
    }

    .font-size {
        font-size: 12px;
    }

    .font-size2 {
        font-size: 11px;
    }

    .match-result {
        font-size: 1rem;
        width: 1.8rem;
        height: 1.8rem;
    }
}

@media only screen and (max-width: 540px) {
    .match-ended {
        font-size: 10px;
    }

    .font-size {
        font-size: 10px;
    }

    .font-size2 {
        font-size: 9px;
    }

    .icon {
        width: 0.5rem;
        height: 0.5rem;
    }

    .match-result {
        font-size: 0.9rem;
        width: 1.3rem;
        height: 1.3rem;
    }
}

@media only screen and (max-width: 420px) {

    .manager-and-team-section {
        line-height: 0.8rem;
    }

    .match-ended {
        font-size: 9px;
    }

    .font-size {
        font-size: 10px;
    }

    .font-size2 {
        font-size: 7px;
    }

    .icon {
        width: 0.5rem;
        height: 0.5rem;
    }
}
