.best-teams-section {
  display: flex;
  flex-direction: column;
}

.const-width-content {
  margin-top: 10px;
}

.best-teams-mobile-view {
  display: none;
}

.best-teams-table-top-content-container {}

.best-teams-top-screen-titles {
  margin: 10px 0;
  color: var(--H3Text2);
}

.best-teams-top-screen-stats-paragraph {
  margin: 10px 0;
  color: var(--H3Text2);
  font-size: 12px;
}

.best-teams-type-button-and-date-container {
  background: var(--H3Light0);
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 52px;
  padding: 0 5px;
}

.best-teams-type-button-container {
  display: flex;
  border: 1px solid var(--H3MidGrey1);
  border-radius: 8px;
}

.best-teams-type-button {
  padding: 7px 20px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  color: var(--H3MidGrey1);
  border-left: 1px solid var(--H3MidGrey1);
  transition-delay: .1s;
  transition-duration: .2s;
}

.best-teams-type-button:first-child {
  border-left: none;
  border-radius: 8px 0 0 8px;
}

.best-teams-type-button:last-child {
  border-radius: 0 8px 8px 0;
}

.active-stat-button {
  background: red;
  border-radius: 2.5px;
  padding: 5px 10px;
}

.best-teams-top-tabs-container {
  background-color: var(--H3Light0);
}

.best-teams-bottom-tabs-container {
  background-color: var(--H3Light1);
  min-height: 35px !important;
}

.best-teams-top-tabs-container .Mui-selected {
  color: var(--H3Dark1) !important;
}

.best-teams-bottom-tabs-container Button {
  color: var(--H3Dark1) !important;
  min-height: 35px !important;
  font-weight: 600;
}

.best-teams-bottom-tabs-section div div {
  max-height: 35px !important;
}

.best-teams-top-tabs {
  font-size: .8rem !important;
  padding: 0 8px !important;
  color: var(--H3MidGrey1) !important;
  font-weight: 600 !important;
}

.best-teams-bottom-tabs {
  font-size: 11px !important;
  padding: 0 8px !important;
  color: #1a3150 !important;
}

/*.best-teams-bottom-tabs-section .css-1ujnqem-MuiTabs-root {
  min-height: 35px !important;
}*/

.best-teams-table-headers th{
  font-size: 12px;
  font-weight: 700;
  color: var(--H3Dark1);
  padding: 0 10px;
  text-align: center;
  background: var(--H3Light0);
}

/*.table-body-section {
  border: 1px solid rgba(224, 224, 224, 1);
}*/

.best-team-content-row td{
  padding: 3px 5px 3px 10px;
  border-bottom: 1px solid var(--H3Light1);
}

.best-team-locked-row td {
  padding: 7px 5px;
}

.best-teams-table-headers th:first-child {
  text-align: left;
}

.team-container {
  display: flex;
  place-items: center;
}

.best-teams-team-logo {
  width: 20px;
  height: 20px;
}

.best-teams-name {
  font-size: 12px;
  font-weight: 600;
  color: var(--H3Dark1);
  cursor: pointer;
}

.best-teams-date-time-and-btts-texts {
  color: var(--H3Dark1);
  font-size: 12px;
}

.best-teams-vs-teams {
  font-size: .7rem;
  font-weight: 700;
  color: var(--H3Dark1);
}

.best-teams-date-time-for-mobile {
  font-size: .6rem;
  font-weight: 500;
  color: var(--H3Dark1);
}

.played-container {
  display: flex;
  justify-content: center;
  flex: 1;
}

.best-teams-played-cont-texts {
  font-size: 13px;
  color: var(--H3Dark1);
}

.played-text {
  font-size: 11px;
  color: var(--H3Dark1);
}

.best-teams-played-cont-texts-2 {
  font-size: 13px;
}

.percent-text {
  font-size: 11px;
  font-weight: 600;
  /*color: black;*/
}

.percent-cell-container {
  display: flex;
  flex-direction: column;
  place-items: center;
  padding: 5px 10px;
}

.loading {
  background-color: #00b1ff;
  width: 100%;
  height: 3px;
}

/*MOBILE VIEW*/
@media only screen and (max-width: 768px) {

  .const-width-content {
    margin-top: 55px;
  }

  .best-teams-table-top-content-container {
    padding: 10px;
  }

  .best-teams-top-screen-titles {
    text-align: center;
  }

  .best-teams-web-view {
    display: none;
  }

  .best-teams-mobile-view {
    display: flex;
  }

  .team-container {
    display: flex;
    place-items: center;
    flex-direction: row;
  }

  .best-teams-match-teams-container {
    display: grid;
  }

  .best-teams-name {
    font-size: 12px;
  }

  .best-teams-top-tabs {
    font-size: .7rem !important;
    padding: 0 !important;
    font-weight: 600 !important;
  }

  .percent-cell-container {
    padding: .4rem .3rem;
  }

  .percent-text {
    font-size: .7rem;
  }

  .best-teams-played-cont-texts {
    font-size: 12px !important;
    color: #1a3150;
  }

  .best-teams-played-cont-texts-2 {
    font-size: 12px !important;
  }

  .best-teams-vs-teams-and-date-time {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/*MOBILE VIEW*/
@media only screen and (max-width: 426px) {

}
