.lmt-card-container {
    position: absolute;
    display: flex;
    place-items: center;
    width: 100%;
    height: 100%;
}

.position-container {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
}
