.blink {
    animation: reveal .5s forwards;
}

@keyframes reveal {
    from {
        clip-path: inset(100% 100% 0 0);
    }
    to {
        clip-path: inset(0 0 0 0);
    }
}

.freekick-container {
    position: absolute;
    display: flex;
    justify-content: center;
    place-items: center;
    width: 100%;
    height: 100%;
    border-radius: 2px;
}

.freekick {
    display: flex;
    place-items: center;
    padding: 0 8px;
    justify-content: space-between;
    background-color: white;
    border-radius: 1px;
    width: 170px;
    height: 40px;
}

.font-size {
    font-size: 13px;
    font-weight: normal;
    text-align: right;
    min-width: 100px;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.freekick-team-name {
    font-size: 10px;
}

.freekick-match-time {
    display: grid;
    place-items: center;
    background-color: #00003C;
    text-align: center;
    font-size: 12px;
    min-width: 24px;
    padding: 0 4px;
    height: 24px;
    border-radius: 2px;
    color: white;
}

.position-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
}
