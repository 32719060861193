
.shot-on-target-section {
    position: absolute;
    display: flex;
    justify-content: center;
    place-items: center;
    width: 100%;
    height: 100%;
}

.blink {
    animation: reveal .5s forwards;
}

@keyframes reveal {
    from {
        clip-path: inset(100% 100% 0 0);
    }
    to {
        clip-path: inset(0 0 0 0);
    }
}

.img-reverse {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.shot-on-target-section {
    display: flex;
    place-items: center;
}

.shoton-target-info {
    display: flex;
    place-items: center;
    padding: 0 8px;
    justify-content: space-between;
    background-color: white;
    border-radius: 1px;
    width: 170px;
    height: 40px;
}

.match-situation-icon {
    width: 3.8rem;
    height: 3.8rem;
}

.away-icon {
    position: absolute;
    display: flex;
    place-items: center;
    height: 100%;
    left: 0;
}

.home-icon {
    position: absolute;
    display: flex;
    place-items: center;
    height: 100%;
    right: 0;
}

.shot-on-target-match-time {
    display: grid;
    place-items: center;
    text-align: center;
    font-size: 12px;
    min-width: 24px;
    padding: 0 4px;
    height: 24px;
    border-radius: 2px;
    color: white;
    background-color: #034586;
}

.font-size {
    font-size: 13px;
    font-weight: normal;
    text-align: right;
    min-width: 100px;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.shot-on-target-team-name {
    font-size: 10px;
}

.shot-on-target-container {
    position: absolute;
    display: flex;
    justify-content: center;
    place-items: center;
    width: 100%;
    height: 100%;
    border-radius: 2px;
}

.shot-on-target {
    display: flex;
    flex-direction: column;
    place-items: center;
    justify-content: space-between;
    min-width: 25%;
}

.position-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
}

