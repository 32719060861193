.no-info {
    display: grid;
    place-items: center;
    font-weight: normal;
    font-size: 14px;
    color: #9c9c9c;
}

.general-stadium {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}

.stadium-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    place-items: center;
}

.stadium-name-section {
    display: grid;
    place-items: center;
}

.font-size {
    font-size: 11px;
}

.font-size2 {
    font-size: 11px;
}

.stad-icon {
    width: 1.2rem;
    height: 1.2rem;
}

.hr {
    height: 1px;
    border-width: 0;
    background-color: #c9c9c9;
}

.stadium-info-container {
    display: grid;
    place-items: center;
}

.futbol-verileri-container {
    display: grid;
    justify-content: center;
    place-items: center;
}

.fv-img {
    width: 60%;
    height: 75%;
}

@media only screen and (max-width: 1264px) {

    .font-size {
        font-size: 15px;
    }

    .font-size2 {
        font-size: 13px;
    }

    .stad-icon {
        width: 2.5rem;
        height: 2.5rem;
    }
}

@media only screen and (max-width: 960px) {
    .font-size {
        font-size: 14px;
    }

    .font-size2 {
        font-size: 12px;
    }

    .stad-icon {
        width: 2.2rem;
        height: 2.2rem;
    }
}

@media only screen and (max-width: 540px) {
    .font-size {
        font-size: 12px;
    }

    .font-size2 {
        font-size: 9px;
    }

    .stad-icon {
        width: 1.4rem;
        height: 1.4rem;
    }
}

@media only screen and (max-width: 420px) {
    .font-size {
        font-size: 10px;
    }

    .font-size2 {
        font-size: 10px;
    }

    .stad-icon {
        width: 1.2rem;
        height: 1.2rem;
    }
}
