.red {
    background-color: #ff2100;
    color: white;
}

.yellow {
    background-color: #ffd300 !important;
    color: black;
}

.blink {
    animation: reveal .5s forwards;
}

@keyframes reveal {
    from {
        clip-path: inset(100% 100% 0 0);
    }
    to {
        clip-path: inset(0 0 0 0);
    }
}

.cards-info {
    display: flex;
    place-items: center;
    padding: 0 8px;
    justify-content: space-between;
    background-color: white;
    border-radius: 1px;
    width: 170px;
    height: 40px;
}

.match-situation-icon {
    width: 3.8rem;
    height: 3.8rem;
}

.match-time {
    display: grid;
    place-items: center;
    text-align: center;
    font-size: 12px;
    min-width: 24px;
    padding: 0 4px;
    height: 24px;
    border-radius: 2px;
}

.cards-container {
    position: absolute;
    display: flex;
    justify-content: center;
    place-items: center;
    width: 100%;
    height: 100%;
    border-radius: 2px;
}

.cards {
    display: flex;
    flex-direction: column;
    place-items: center;
    justify-content: space-between;
    min-width: 25%;
    height: 120px;
}

.cards-font-size {
    font-size: 13px;
    font-weight: normal;
    text-align: right;
    min-width: 100px;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cards-team-name {
    font-size: 10px;
}

.position-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
}
