.update-teams-name-section {
    width: 100%;
    background: white;
    border-radius: 20px;
    display: grid;
    place-items: center;
    margin: 10px 0;
}

.update-teams-name-container {
    display: grid;
    width: 60%;
}

.update-teams-name-form-control-container {
    width: 60%;
}

.update-button-container {
    margin-bottom: 10px;
}

/*MOBILE VIEW*/
@media only screen and (max-width: 768px) {

    .update-teams-name-section {
        margin-top: 70px;
    }

    .update-teams-name-container {
        width: 75%;
    }

    .update-teams-name-form-control-container {
        width: 75%;
    }
}
