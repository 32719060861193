.general-cards-section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    place-items: center;
    height: 100%;
}

.number-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

.home-percent{
    display: flex;
    place-items: center;
    justify-content: space-between;
    width: 100%;
}

.progress{
    display: flex;
    height: 2px;
    background: darkblue;
}

.font-size {
    font-size: 12px;
}

.away-percent {
    display: flex;
    height: 2px;
    background: red;
}

.home-number {
    display: flex;
    justify-content: center;
    place-items: center;
    font-size: 12px;
    color: darkblue;
}

.away-number {
    display: flex;
    justify-content: center;
    place-items: center;
    font-size: 12px;
    color: red;
}

.percent-font-size {
    font-size: 12px;
}

@media only screen and (max-width: 1264px) {
    .font-size {
        font-size: 15px;
    }

    .home-number {
        font-size: 15px;
    }

    .away-number {
        font-size: 15px;
    }
}

@media only screen and (max-width: 960px) {
    .font-size {
        font-size: 13px;
    }

    .home-number {
        font-size: 13px;
    }

    .away-number {
        font-size: 13px;
    }
}

@media only screen and (max-width: 540px) {
    .font-size {
        font-size: 11px;
    }

    .home-number {
        font-size: 9px;
    }

    .away-number {
        font-size: 9px;
    }
}

@media only screen and (max-width: 420px) {
    .font-size {
        font-size: 10px;
    }

    .home-number {
        font-size: 12px;
    }

    .away-number {
        font-size: 12px;
    }
}
