.sr-lmt-corner-bottom__banners {
    background-repeat: repeat-x;
    background-size: auto 100%;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.sr-lmt-corner-bottom__banners-left {
    background-position: 100% 50%;
}

.sr-lmt-corner-bottom__banners-wrapper-left {
    height: 5%;
    position: absolute;
    right: 0;
    top: 5.1%;
    -webkit-transform: skewY(
            -15deg) translateY(-50%) perspective(387px) rotateY(
            14deg);
    transform: skewY(
            -15deg) translateY(-50%) perspective(387px) rotateY(
            14deg);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    width: 100%;
}

.aa {
    width: 100%;
    height: 100%;
}

.blink {
    animation: reveal .5s forwards;
}

@keyframes reveal {
    from {
        clip-path: inset(100% 100% 0 0);
    }
    to {
        clip-path: inset(0 0 0 0);
    }
}

.match-situation-icon {
    width: 3.8rem;
    height: 3.8rem;
}

.corner-info {
    display: flex;
    place-items: center;
    padding: 0 8px;
    justify-content: space-between;
    background-color: white;
    border-radius: 1px;
    width: 170px;
    height: 50px;
}

.icon {
    display: flex;
    justify-content: center;
}

.corner-match-time {
    display: grid;
    place-items: center;
    text-align: center;
    font-size: 12px;
    min-width: 24px;
    padding: 0 4px;
    height: 24px;
    border-radius: 2px;
    color: white;
    background-color: #43c5cb;
}

.corner-container {
    bottom: 16px;
    left: 0;
    position: absolute;
    right: 0;
}

.corner {
    display: flex;
    justify-content: flex-end;
}

.font-size {
    font-size: 13px;
    font-weight: normal;
    text-align: right;
    min-width: 100px;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.corner-team-name {
    font-size: 10px;
}


.sr-lmt-corner-bottom__banner-container {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    will-change: opacity;
}

.sr-lmt-corner-bottom__animation {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.sr-bb * {
    box-sizing: border-box;
    min-height: auto;
    position: static;
    z-index: auto;
}

.sr-lmt-corner-bottom__animation-4 {
    stroke-dasharray: 13 15;
    stroke-linejoin: round;
    fill: none;
}

.sr-lmt-corner-bottom__animation-3 {
    stroke-dasharray: 39 41;
    stroke-linejoin: round;
    fill: none;
}

.sr-lmt-corner-bottom__animation-2 {
    stroke-dasharray: 208 210;
    stroke-miterlimit: 10;
    fill: none;
}

.sr-lmt-corner-bottom__animation-1 {
    stroke-dasharray: 105 107;
    stroke-miterlimit: 10;
    fill: none;
}

.sr-lmt-corner-bottom__animation-0 {
    stroke-dasharray: 310 312;
    stroke-miterlimit: 10;
    fill: none;
}

.sr-lmt-corner-bottom__opacity {
    opacity: .6;
}

.sr-lmt-corner-bottom__animation-7 {
    stroke-dasharray: 53 55;
    stroke-linejoin: round;
    stroke-width: 3;
    fill: none;
}

.sr-lmt-corner-bottom__animation-6 {
    stroke-dasharray: 56 58;
    stroke-linejoin: round;
    fill: none;
}

.sr-lmt-corner-bottom__overlay-shadow {
    background: radial-gradient(ellipse closest-corner at 50% 50%, transparent 0, rgba(0, 0, 0, .15) 100%);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.sr-lmt-corner-bottom__grass {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

.sr-lmt-event-corner__wrapper {
    contain: content;
    height: 100%;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
}

.sr-lmt-corner-bottom__container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

sr-lmt-event-corner__ball-path srm-is-left .sr-lmt-corner-bottom__overlay {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    will-change: opacity;
}

.sr-bb svg:not(:root) {
    overflow: hidden;
}

.sr-bb * {
    -webkit-text-stroke: 1px transparent;
}

.sr-lmt-event-corner__ball-path.srm-is-left {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.sr-lmt-event-corner__ball-path {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.sr-lmt-ballpath__wrapper {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.sr-lmt-svg {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.sr-lmt-ballpath__ball-shade {
    fill: #1b1b1b;
    stroke: #1b1b1b;
}

.sr-lmt-ballpath__path-shade {
    stroke-width: 2;
    stroke-opacity: .15;
    stroke: #1b1b1b;
}

.sr-lmt-ballpath__path {
    stroke-width: 2;
}

.sr-lmt-corner-bottom__overlay {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    will-change: opacity;
}

.sr-lmt-state {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.sr-lmt-corner-bottom__grass-container {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    will-change: opacity;
}

.sr-lmt-corner-bottom__overlay {
    /*background-color: #5c8301;*/
    background-color: #459153;
}
