.counter-section {
    display: flex;
    place-items: center;
    flex-direction: column;
    justify-content: space-around;
}

.counter-container {
    display: flex;
    justify-content: space-between;
    place-items: center;
    padding: 0 0.5rem;
}

.counter {
    width: 25%;
    display: flex;
    flex-direction: row;
    background-color: #00aa56;
}

.kickoff-and-match-starting {
    font-size: 12px;
}

.counter-time-container {
    display: flex;
    flex-direction: column;
    place-items: center;
    width: 100%;
}

.counter-time {
    display: grid;
    place-items: center;
    padding: 5px;
    background-color: red;
    border-radius: 3px;
    color: white;
    margin: 0 0.5rem;
    font-size: 12px;
    font-weight: bold;
}


@media only screen and (max-width: 1264px) {

    .kickoff-and-match-starting {
        font-size: 14px;
    }

    .time {
        font-size: 16px;
    }
}

@media only screen and (max-width: 960px) {
    .kickoff-and-match-starting {
        font-size: 12px;
    }

    .time {
        font-size: 15px;
        margin: 0 0.3rem;
    }
}

@media only screen and (max-width: 540px) {
    .kickoff-and-match-starting {
        font-size: 10px;
    }

    .time {
        font-size: 10px;
        margin: 0 0.2rem;
    }
}

@media only screen and (max-width: 420px) {
    .kickoff-and-match-starting {
        font-size: 8px;
    }

    .time {
        font-size: 8px;
        margin: 0 0.2rem;
    }
}
