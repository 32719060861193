.blink {
    animation: reveal .5s forwards;
}

@keyframes reveal {
    from {
        clip-path: inset(100% 0 0 100%);
    }
    to {
        clip-path: inset(0 0 0 0);
    }
}

.goal-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    height: 75px;
    margin-top: 12px;
    align-items: end;
}

.goal-text-and-team {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    place-items: center;
    min-width: 35%;
    max-width: 45%;
    height: 36px;
    background-color: white;
    border-radius: 0 0 0 10px;
}

.time-and-result {
    display: flex;
    flex-direction: row;
    place-items: center;
    justify-content: space-around;
    width: 17%;
    height: 30px;
    background-color: white;
    border-radius: 0 0 0 10px;
    font-size: 15px;
}

.goal-text {
    font-size: 15px;
    font-weight: bolder;
}

.team-text {
    font-size: 13px;
    font-weight: 500;
}

.match-time {
    display: grid;
    place-items: center;
    text-align: center;
    font-size: 12px;
    min-width: 24px;
    padding: 0 4px;
    height: 24px;
    border-radius: 2px;
    color: white;
    background-color: red;
}

.match-score {
    display: flex;
    justify-content: space-around;
    animation: .8s fadeIn;
    animation-fill-mode: forwards;
    visibility: hidden;
}

.result {
    font-size: 12px;
    font-weight: bolder;
}

@keyframes fadeIn {
    99% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
}

.banner-width {
    height: 60%;
    width: 99%;
}




.sr-lmt-event-goal__wrapper {
    height: 100%;
    position: relative;
    width: 100%;
}

.sr-lmt-event-goal__anim-bg {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}

.sr-lmt-goal__container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.sr-lmt-goal__overlay {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.sr-lmt-goal__overlay {
    /*background-color: #5c8301;*/
    background-color: #459153;
}

.sr-lmt-goal__grass-container, .sr-lmt-goal__overlay {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.sr-lmt-goal__grass {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

.sr-bb * {
    box-sizing: border-box;
    min-height: auto;
    position: static;
    z-index: auto;
}

.sr-lmt-goal__overlay-shadow {
    background: radial-gradient(ellipse closest-corner at 50% 50%,transparent 0,rgba(0,0,0,.15) 100%);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.sr-lmt-goal__noise {
    background-size: 40px 40px;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.sr-lmt-goal__banner-container {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.sr-lmt-goal__banner-svg {
    bottom: 0;
    -webkit-filter: drop-shadow(0 -3px 5px rgba(0,0,0,.25));
    filter: drop-shadow(0 -3px 5px rgba(0,0,0,.25));
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.sr-bb svg:not(:root) {
    overflow: hidden;
}

.sr-lmt-goal__banners-wrapper-left {
    height: 5.75%;
    position: absolute;
    right: 11.5%;
    top: 10.5%;
    -webkit-transform: skewY(
            -14.95deg) translateY(-50%) perspective(387px) rotateY(
            14deg);
    transform: skewY(
            -14.95deg) translateY(-50%) perspective(387px) rotateY(
            14deg);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    width: 100%;
}

.sr-lmt-goal__banners-left.srm-is-right {
    background-position: 0 50%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.sr-lmt-goal__banners {
    background-repeat: repeat-x;
    background-size: auto 100%;
    left: 0;
    position: absolute;
    top: 0;
}

.sr-lmt-goal__banners-left {
    background-position: 100% 50%;
}

.sr-lmt-goal__banners-wrapper-right {
    height: 5.75%;
    left: 89.7%;
    position: absolute;
    top: 10.5%;
    -webkit-transform: skewY(
            22.4deg) translateY(-50%) perspective(387px) rotateY(
            -25deg);
    transform: skewY(
            22.4deg) translateY(-50%) perspective(387px) rotateY(
            -25deg);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    width: 20%;
}

.sr-lmt-goal__banners-right.srm-is-right {
    background-position: 100% 50%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.sr-lmt-goal__animation {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.sr-lmt-goal__animation-0 {
    stroke-dasharray: 304 306;
    stroke-linejoin: round;
    fill: none;
}

.sr-lmt-goal__animation-1 {
    stroke-dasharray: 316 318;
    stroke-linejoin: round;
    fill: none;
}

.sr-lmt-goal__animation-2 {
    stroke-dasharray: 120 122;
    stroke-linejoin: round;
    fill: none;
}

.sr-lmt-goal__animation-3 {
    stroke-dasharray: 97 99;
    stroke-linejoin: round;
    fill: none;
}

.sr-lmt-goal__animation-8 {
    stroke-dasharray: 9 11;
    stroke-linejoin: round;
    fill: none;
}

.sr-lmt-goal__animation-9 {
    stroke-dasharray: 20 22;
    stroke-linejoin: round;
    fill: none;
}

.sr-lmt-goal__animation-10 {
    stroke-dasharray: 7 9;
}

.sr-lmt-goal__animation-4 {
    stroke-dasharray: 81 83;
    stroke-linejoin: round;
    fill: none;
    stroke-width: 2;
}

.sr-lmt-goal__animation-5 {
    stroke-dasharray: 33 35;
    stroke-linejoin: round;
    fill: none;
}

.sr-lmt-goal__animation-6 {
    stroke-dasharray: 46 48;
    stroke-linejoin: round;
    fill: none;
}

.sr-lmt-goal__animation-7 {
    stroke-dasharray: 32 34;
    stroke-linejoin: round;
    fill: none;
}

.sr-lmt-event-goal__path-marker {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.sr-lmt-ballpath__wrapper {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.sr-lmt-svg {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.sr-lmt-ballpath__ball-shade {
    fill: #1b1b1b;
    stroke: #1b1b1b;
}

.sr-lmt-ballpath__path-shade {
    stroke-width: 2;
    stroke-opacity: .15;
    stroke: #1b1b1b;
}

.sr-lmt-ballpath__path {
    stroke-width: 2;
}

.sr-lmt-goal__container {
    -webkit-transform: scaleX(-1);
    /* transform: scaleX(-1); */
}
