.fv-lmt-content-wrapper-expanded {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
}

.a {
    width: 100%;
    height: auto;
}

.row {
    margin: 0;
    padding: 0;
}

.col-12 {
    margin: 0;
    padding: 0;
}

.circle-ripple {
    background-color: white;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    animation: ripple 0.7s linear infinite;
}

@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(192, 192, 192, 0.3),
        0 0 0 1em rgba(192, 192, 192, 0.3),
        0 0 0 2em rgba(192, 192, 192, 0.3);
    }
    100% {
        box-shadow: 0 0 0 1em rgba(192, 192, 192, 0.3),
        0 0 0 2em rgba(192, 192, 192, 0.3),
        0 0 0 3em rgba(192, 192, 192, 0.3);
    }
}

.img {
    position: absolute;
}

.vertical-line {
    margin: 0 5px;
}

.time-min-sec {
    font-size: 13px;
}

.injury-time {
    color: red;
    margin-left: 3px
}

.blink-animation {
    -webkit-animation: blink 1s linear infinite;
    -moz-animation: blink 1s linear infinite;
    animation: blink 1s linear infinite;
}

@keyframes blink {
    0% {
        fill: #00000044;
    }
    25% {
        fill: #00000022;
    }
    50% {
        fill: #00000000;
    }
    75% {
        fill: #00000022;
    }
    100% {
        fill: #00000044;
    }
}

@-webkit-keyframes blink {
    0% {
        color: red;
    }
    100% {
        color: black;
    }
}

.header-container {
    display: flex;
    width: 568.5px;
    background-color: #e2e2e2;
}

.score-and-match-status {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.header-teams-times-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: large;
}

.time-container {
    position: absolute;
    display: flex;
    justify-content: center;
    place-items: center;
    top: 3%;
    z-index: 2;
    width: 100%;
    height: auto;
}

.time {
    display: flex;
    place-items: center;
    padding: 0 0.5em;
    flex-direction: column;
    justify-content: space-around;
    background-color: white;
    border-radius: 1px;
    font-size: 14px;
    min-width: 10%;
}

.round-container {
    display: flex;
    place-items: center;
}

.score-container {
    display: flex;
}

.round-p {
    font-size: 12px;
    font-weight: lighter;
}

.lmt-match-result {
    display: grid;
    place-items: center;
}

.fv-lmt-wrapper {
    position: relative;
    width: 100%;
    /*background-color: #5c8301;*/
    background-color: #459153;
    background-repeat: repeat;
}

.fv-lmt-bg-noise {
    background-image: url("../../assets/lmt-v2/lmt-substructure.png");
    background-size: 40px 40px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-repeat: initial;
}

.fv-lmt-stripes {
    position: absolute;
    left: 6.7%;
    right: 1.8%;
    top: 3%;
    bottom: 3%;
    background: repeating-linear-gradient(
            to right,
            #4B9D5B,
            #4B9D5B 6.66%,
            transparent 6.66%,
            transparent 13.33%
    );
    /*background: repeating-linear-gradient(
        to right,
        hsla(0, 0%, 100%, .08),
        hsla(0, 0%, 100%, .08) 6.66%,
        transparent 6.66%,
        transparent 13.33%
    );*/
}

.fv-lmt-overlay {
    position: absolute;
    background: -webkit-radial-gradient(ellipse closest-corner at 50% 50%, transparent 0, rgba(0, 0, 0, .15) 100%);
    background: radial-gradient(ellipse closest-corner at 50% 50%, transparent 0, rgba(0, 0, 0, .15) 100%);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.fv-lmt-content-wrapper {
    position: absolute;
    left: 1.8%;
    right: 1.8%;
    top: 3%;
    bottom: 3%;
    z-index: 2;
}

.fv-lmt-pitch-wrapper {
    width: 100%;
    height: 100%;
    opacity: .54;
}

.LMT-card-container {
    position: absolute;
    display: flex;
    place-items: center;
    width: 100%;
    height: 100%;
}

.position-container {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
}

@media only screen and (max-width: 768px) {
    .fvLmt-general-section {
        margin-top: 70px;
    }
}

@media only screen and (max-width: 420px) {
    .round-p {
        font-size: 10px;
        font-weight: lighter;
    }
}
