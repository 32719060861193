.isLmtOn {
    display: none;
}

.footer-general-section {
    background: white;
    display: flex;
    flex-direction: column;
    place-items: center;
    min-height: 140px;
    margin-top: 20px;
}

.footer-top-container {
    display: flex;
    justify-content: space-evenly;
    place-items: center;
    width: 100%;
}

.footer-container {
    display: flex;
    flex-direction: column;
    height: 160px;
    margin-top: 10px;
}

.footer-ul-title {
    color: #3e2d56;
    font-size: 13px;
    font-weight: 600;
}

.footer-ul-container {
    padding: 0;
}

.footer-li {
    display: flex;
    place-items: center;
    list-style-type: none;
    padding: 5px 0;
    color: #778aa4;
    font-size: 12px;
}

.footer-li a {
    text-decoration: none;
    color: #778aa4;
}

.social-media-icons {
    margin-right: 5px;
}

.footer-logo-text {
    color: #162b46;
    font-weight: 700;
    font-size: 1rem;
}

.footer_play_store {
    width: 120px;
    height: 38px;
    background-size: cover;
    background-position: center center;
    background-image: url('../../assets/icons/play-store.svg');
}

.footer_app_store {
    width: 120px;
    height: 38px;
    background-size: cover;
    background-position: center center;
    background-image: url("../../assets/icons/app-store.svg");
    margin-bottom: 10px;
}

.flags {
    margin-right: 5px;
    width: 20px;
    height: 20px;
}

.footer-bottom-container {
    display: flex;
    flex-direction: column;
    place-items: center;
}

.logo-style {
    display: block;
    width: 200px;
    height: 30px;
    margin: 0 auto 15px auto;
}

.copyright-text {
    color: #778aa4;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
}

.footer-title-h1 {
    color: black;
    font-size: 17px;
    font-weight: 600;
    text-align: center;
}

.gamble-text {
    color: #778aa4;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
}

.followers-text {
    color: red;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
}

.footer-text {
    text-align: center;
    color: #778aa4;
    font-size: 12px;
    padding-bottom: 10px;
}

@media only screen and (max-width: 768px) {
    .footer-top-container {
        background: white;
        display: grid;
        grid-template-columns: repeat(2,auto);
    }
    .footer-ul-title {
        margin-top: 10px;
    }

    .footer-bottom-container {
        margin-top: 100px;
    }

    .footer-container {
        height: 190px;
    }

    .footer-text {
        padding-bottom: 60px;
    }
}
